@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.sidebar-expanded .sidebar-expanded\:rotate-180 {
  transform: rotate(180deg);
}

.sidebar-expanded .sidebar-expanded\:opacity-100 {
  opacity: 1;
}

.sidebar-expanded .sidebar-expanded\:block {
  display: block;
}
.table-header {
  @apply px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap font-semibold text-slate-500;
  min-width: fit-content;
}

.table-cell {
  @apply px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap;
  min-width: fit-content;
}


body {
  @apply leading-[normal] m-0;
}

*,
::before,
::after {
  border-width: 0;
}
